import { on } from 'delegated-events'

const REQUIRED_FIELDS = ['first_name', 'last_name', 'email', 'request_details']

const INPUT_ERROR_CLASS = 'base-input--error'

const arrowIcon = `
  <div class="button-arrow">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g clip-path="url(#a)">
        <path fill="#000" d="M10.205 3.338a.583.583 0 0 0-.825.825l5.045 5.045H3.75a.583.583 0 1 0 0 1.167h10.674L9.38 15.42a.583.583 0 0 0 .825.825l6.04-6.04a.583.583 0 0 0 0-.826l-6.04-6.04Z"/>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#000" d="M0 0h20v20H0z"/>
        </clipPath>
      </defs>
    </svg>
  </div>
`

const successIcon = `
  <div class="button-success-icon">
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="currentColor"/>
      <path d="M17.2308 10L11.3077 15.5L7.5 11.6923" stroke="white" stroke-width="3" stroke-linecap="round"/>
    </svg>
  </div>
`
const alertIcon = `
  <div class="button-alert-icon">
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="currentColor"/>
      <path d="M7.17871 16.8216L16.8216 7.17871M7.17871 7.17871L16.8216 16.8216" stroke="white" stroke-width="2.05714" stroke-linecap="round"/>
    </svg>
  </div>
`

on('submit', 'form#websummit-form', e => {
  e.preventDefault()
  const form = e.currentTarget
  const submitButton = document.getElementById('websummit-form-submit')

  let hasErrors = false

  REQUIRED_FIELDS.forEach(field => {
    const input = document.getElementById(field)
    if (input) {
      input.classList.remove(INPUT_ERROR_CLASS)

      if (!input.value || !input.value.length) {
        hasErrors = true
        input.classList.add(INPUT_ERROR_CLASS)
      }
    }
  })

  if (hasErrors) {
    return
  }

  const inputValues = REQUIRED_FIELDS.reduce((agg, field) => {
    const input = document.getElementById(field)
    if (input) {
      return {
        ...agg,
        [field]: input.value
      }
    }

    return agg
  }, {})

  submitButton.setAttribute('disabled', 'disabled')

  fetch(form.action, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(inputValues)
  })
    .then(() => {
      submitButton.classList.add('success')
      submitButton.innerHTML = `${successIcon} Request sent`
    })
    .catch(() => {
      submitButton.classList.add('danger')
      submitButton.innerHTML = `${alertIcon} Submitting went wrong`
    })
    .finally(() => {
      submitButton.removeAttribute('disabled')
      form.reset()

      setTimeout(function () {
        submitButton.innerHTML = `SEND REQUEST ${arrowIcon}`
        submitButton.classList.remove('success', 'danger')
      }, 5000)
    })
})

on('change', 'form#websummit-form .base-input', function () {
  this.classList.remove(INPUT_ERROR_CLASS)
})
