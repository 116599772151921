import { onDomReady } from 'utils/onDomReady'
import { on } from 'delegated-events'
import { trackEvent } from 'api/index'

const LIST_HORIZONTAL_CLASSNAME = 'destinations-carousel--horizontal'

const carouselList = () =>
  document.querySelector('.destinations-carousel__list')

let swiperInstance

const getDashboardItems = () =>
  Array.from(document.querySelectorAll('.destinations-carousel-item__wrap'))

const removedItems = []

const originalDashboardItems = []

function saveOriginalOrder() {
  const dashboardItems = getDashboardItems()
  originalDashboardItems.push(...dashboardItems)
}

function restoreOriginalOrder() {
  const carousel = carouselList()
  originalDashboardItems.forEach(item => {
    carousel.appendChild(item)
  })
}

function showElementsHorizontally() {
  if (window.matchMedia('(max-width: 749px)').matches) {
    return getDashboardItems().length < 3
  }
  if (window.matchMedia('(max-width: 1023px)').matches) {
    return getDashboardItems().length < 5
  }
  return getDashboardItems().length < 10
}

function initializeSwiper() {
  const carouselListElement = document.querySelector('.destinations-carousel')
  if (!carouselListElement) return

  if (showElementsHorizontally()) {
    carouselListElement.classList.add(LIST_HORIZONTAL_CLASSNAME)
    if (swiperInstance) {
      swiperInstance.destroy()
      swiperInstance = null
    }

    return
  }

  const carouselElement = document.querySelector(
    '.destinations-carousel__slider'
  )
  if (!carouselElement) return

  import('swiper').then(({ default: Swiper, Navigation, Grid }) => {
    Swiper.use([Navigation, Grid])

    swiperInstance = new Swiper(carouselElement, {
      slidesPerView: 1.05,
      grid: {
        rows: 3
      },
      navigation: {
        nextEl: '.destinations-carousel__control_next',
        prevEl: '.destinations-carousel__control_prev'
      },
      breakpoints: {
        750: {
          slidesPerView: 2.2
        },
        1024: {
          slidesPerView: 3,
          grid: {
            rows: 3
          }
        }
      }
    })

    carouselListElement.classList.remove(LIST_HORIZONTAL_CLASSNAME)
  })
}

onDomReady(() => {
  saveOriginalOrder()
  initializeSwiper()
  window.addEventListener('resize', initializeSwiper)
})

on('change', 'input[name="destinations-tab"]', e => {
  const input = e.target
  const { tag } = input.dataset
  if (!tag) return

  trackEvent('Marketing:Destinations:Category selected', { name: tag })

  restoreOriginalOrder()

  getDashboardItems().forEach(item => {
    if (!(tag === 'All' || item.dataset.tag === tag)) {
      removedItems.push(item)
      item.remove()
    }
  })

  initializeSwiper()
})
