const appDomain = () => {
  const element = document.head.querySelector(
    "meta[name='cpl-app-domain-host']"
  )
  if (element) {
    return element.getAttribute('content')
  }

  return null
}

export default appDomain
