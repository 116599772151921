import { onDomReady } from 'utils/onDomReady'

onDomReady(() => {
  const tippyElements = document.querySelectorAll('[data-tippy-content]')
  if (!tippyElements || !tippyElements.length) return

  import('tippy.js').then(({ default: tippy }) => {
    tippy(tippyElements, {
      placement: 'bottom'
    })
  })
})
