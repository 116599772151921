import { onDomReady } from 'utils/onDomReady'

onDomReady(() => {
  const tabs = document.querySelectorAll('.roles-toggle__input')
  const tabContents = document.querySelectorAll('.roles-tab')

  tabs.forEach((tab, index) => {
    tab.addEventListener('change', () => {
      tabContents.forEach(content => content.classList.remove('active'))
      tabContents[index].classList.add('active')
    })
  })
})
