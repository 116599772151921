import { onDomReady } from 'utils/onDomReady'
import { loadScript } from 'utils/loadScript'

const loadWistia = () =>
  loadScript('https://fast.wistia.com/assets/external/E-v1.js')

const intersectionCallback = (entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      loadWistia().then(() => observer.disconnect())
    }
  })
}

onDomReady(() => {
  const targets = document.querySelectorAll('.mainFeatureVideo')
  if (targets.length == 0) return

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3
  }
  const observer = new IntersectionObserver(intersectionCallback, options)
  targets.forEach(target => {
    observer.observe(target)
  })
})
