import { Application } from '@hotwired/stimulus'
import * as Sentry from '@sentry/browser'

const application = Application.start()

// Configure Stimulus development experience
if (process.env.RAILS_ENV === 'development') {
  application.debug = true
  window.Stimulus = application
}

application.handleError = (error, message, detail) => {
  Sentry.setContext('errorDetails', { message, detail })
  Sentry.captureException(error)
}

export { application }
