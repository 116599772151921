import axios from 'axios'

import { getCsrfToken } from 'utils/csrf'
import appDomain from './appDomain'

const SAFE_METHODS = ['head', 'get']

const requiresForgeryProtection = ({ method }) => {
  if (!method) return false
  return !SAFE_METHODS.includes(method.toLowerCase())
}

const instance = axios.create({
  withCredentials: true,
  baseURL: `//${appDomain()}`
})

instance.interceptors.request.use(
  config => {
    if (requiresForgeryProtection(config)) {
      config.headers['X-CSRF-Token'] = getCsrfToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance
