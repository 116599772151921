import { onDomReady } from 'utils/onDomReady'

const updateControlsVisibility = carouselElement => {
  const carouselElementWrapper = carouselElement.parentElement

  const prevControl = carouselElementWrapper.querySelector(
    '.horizontal-carousel__control_prev'
  )
  const nextControl = carouselElementWrapper.querySelector(
    '.horizontal-carousel__control_next'
  )
  const arrowsControls = carouselElementWrapper.querySelector(
    '.horizontal-carousel__controls'
  )

  const isAtTop = carouselElement.scrollTop === 0
  const isAtBottom =
    carouselElement.scrollTop + carouselElement.clientHeight + 1 >=
    carouselElement.scrollHeight

  const isContentFits =
    carouselElement.scrollHeight <= carouselElement.clientHeight

  prevControl.classList.toggle('swiper-button-disabled', isAtTop)
  nextControl.classList.toggle('swiper-button-disabled', isAtBottom)
  arrowsControls.classList.toggle(
    'horizontal-carousel__controls--hidden',
    isContentFits
  )
  carouselElementWrapper.classList.toggle('no-scroll', isContentFits)
}

onDomReady(() => {
  let scrollInterval

  const scrollAmount = 150

  const startScrolling = (element, direction) => {
    stopScrolling()
    scrollInterval = setInterval(() => {
      scrollTo(element, direction)
    }, 300)
  }

  const stopScrolling = () => {
    clearInterval(scrollInterval)
  }

  const scrollTo = (element, direction) => {
    const scrollIncrement = direction === 'up' ? -scrollAmount : scrollAmount
    element.scrollBy({
      top: scrollIncrement,
      behavior: 'smooth'
    })
  }

  const carousels = document.querySelectorAll('.horizontal-carousel')

  if (!carousels.length) return

  carousels.forEach(carouselElement => {
    const prevControl = carouselElement.parentElement.querySelector(
      '.horizontal-carousel__control_prev'
    )
    const nextControl = carouselElement.parentElement.querySelector(
      '.horizontal-carousel__control_next'
    )
    const arrowsControls = carouselElement.parentElement.querySelector(
      '.horizontal-carousel__controls'
    )

    if (!prevControl || !nextControl || !arrowsControls) return

    prevControl.addEventListener('mousedown', () => {
      startScrolling(carouselElement, 'up')
    })

    prevControl.addEventListener('mouseup', stopScrolling)

    nextControl.addEventListener('mousedown', () => {
      startScrolling(carouselElement, 'down')
    })

    nextControl.addEventListener('mouseup', stopScrolling)

    prevControl.addEventListener('click', () => {
      scrollTo(carouselElement, 'up')
    })

    nextControl.addEventListener('click', () => {
      scrollTo(carouselElement, 'down')
    })

    carouselElement.addEventListener('scroll', () => {
      updateControlsVisibility(carouselElement)
    })

    updateControlsVisibility(carouselElement)
  })
})

export default updateControlsVisibility
