import { on } from 'delegated-events'
import { onDomReady } from 'utils/onDomReady'
const NAVIGATION_BUTTON = '.navigation-item-button--submenu'
const NAVIGATION_MENU = '.navigation-submenu'
const NAVIGATION_ITEM = '.navigation-item'
const NAVIGATION_OVERLAY = '.navigation__overlay'

const ACTIVE_CLASSNAME = 'active'
const HEADER_TRANSPARENT_CLASSNAME = 'header--transparent'

const navigationOverlay = document.querySelector(NAVIGATION_OVERLAY)
const buttons = document.querySelectorAll(NAVIGATION_BUTTON)
const submenus = document.querySelectorAll(NAVIGATION_MENU)

const isNormalScreen = window.matchMedia('(min-width: 1024px)').matches

const header = document.querySelector('.header')

const addHeaderTransparentClass = () => {
  if (window.location.pathname === '/') {
    header.classList.add(HEADER_TRANSPARENT_CLASSNAME)
  }
}

const removeHeaderTransparentClassOnScroll = () => {
  const isSubMenuActive =
    document.querySelector(`${NAVIGATION_OVERLAY}.${ACTIVE_CLASSNAME}`) !== null

  if (window.scrollY > 0) {
    header.classList.remove(HEADER_TRANSPARENT_CLASSNAME)
  } else if (!isSubMenuActive) {
    addHeaderTransparentClass()
  }
}

const removeItemActive = () => {
  submenus.forEach(submenu => {
    submenu.style.maxHeight = ''
  })

  buttons.forEach(button => {
    button.closest(NAVIGATION_ITEM).classList.remove(ACTIVE_CLASSNAME)
  })
}

const closeNavigation = () => {
  submenus.forEach(submenu => {
    submenu.style.transition = ''
  })

  navigationOverlay.classList.remove(ACTIVE_CLASSNAME)

  removeItemActive()

  if (window.scrollY === 0) {
    addHeaderTransparentClass()
  }
}

const handleButtonClick = event => {
  const parentItem = event.target.closest(NAVIGATION_ITEM)
  const submenuItem = event.target.nextElementSibling
  const isActive = parentItem.classList.contains(ACTIVE_CLASSNAME)
  const contentHeight = submenuItem.scrollHeight

  const hasActiveItem =
    Array.from(
      document.querySelectorAll(`${NAVIGATION_ITEM}.${ACTIVE_CLASSNAME}`)
    ).length > 0

  if (isNormalScreen && hasActiveItem) {
    submenus.forEach(submenu => {
      submenu.style.transition = isActive ? '' : 'none'
    })

    navigationOverlay.classList.remove(ACTIVE_CLASSNAME)

    if (window.scrollY === 0) {
      addHeaderTransparentClass()
    }
  }

  removeItemActive()

  if (!isActive) {
    parentItem.classList.add(ACTIVE_CLASSNAME)
    submenuItem.style.maxHeight = `${contentHeight}px`

    if (isNormalScreen) {
      navigationOverlay.classList.add(ACTIVE_CLASSNAME)
    }
    if (window.scrollY === 0) {
      header.classList.remove(HEADER_TRANSPARENT_CLASSNAME)
    }
  }
}

on('click', NAVIGATION_BUTTON, handleButtonClick)

on(
  'click',
  `.navigation-submenu__action--video, ${NAVIGATION_OVERLAY}`,
  closeNavigation
)

onDomReady(() => {
  var path = window.location.pathname
  var menuLinks = Array.from(document.querySelectorAll('.navigation a'))

  menuLinks.forEach(link => {
    if (link.getAttribute('href') === path) {
      link.classList.add('active')
    }
  })

  addHeaderTransparentClass()

  window.addEventListener('scroll', removeHeaderTransparentClassOnScroll)
})
