import { onDomReady } from 'utils/onDomReady'
import debounce from 'utils/debounce'
import { on } from 'delegated-events'
import { trackEvent } from 'api/index'

const DASHBOARD_SEARCH_SELECTOR = '.dashboards-search__input'
const ITEM_HIDDEN_CLASSNAME = 'dashboard--hidden'
const DASHBOARD_MULTISELECT_CLEAR_SELECTOR =
  '.dashboards__filter .multiselect__clear'
const DASHBOARD_LOAD_MORE_SELECTOR = '.load-more-dashboards'
const TAB_SELECTOR = '.dashboards-filter-tab'
const TAB_MOBILE_SELECTOR = '.data-filter-mobile'

const itemsPerPage = 10
let visibleItemsCount = itemsPerPage
let categoryValue = 'All'
let sourceValue = []
let destinationValue = []
let searchValue = ''
let fullItemList = []
let initialOrder = []

function updateURL() {
  const urlParams = new URLSearchParams()

  if (categoryValue && categoryValue !== 'All') {
    urlParams.append('category', categoryValue)
  }

  if (sourceValue.length > 0) {
    sourceValue.forEach(source => {
      urlParams.append('source', source)
    })
  }

  if (destinationValue.length > 0) {
    destinationValue.forEach(destination => {
      urlParams.append('destination', destination)
    })
  }

  window.history.replaceState(null, '', `?${urlParams.toString()}`)
}

const scrollToStart = function () {
  const start = document.querySelector(
    '.data-filter-section--dashboards .data-filter-section__box .anchor'
  )
  start.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

const handleCheckboxChange = function () {
  const value = this.value
  let array, eventName

  switch (this.name) {
    case 'sources-checkboxes':
      array = sourceValue
      eventName = 'Marketing:Templates:Source selected'
      break
    case 'destinations-checkboxes':
      array = destinationValue
      eventName = 'Marketing:Templates:Destination selected'
      break
    default:
      return
  }

  if (this.checked) array.push(value)
  else array.splice(array.indexOf(value), 1)

  updateURL()
  filterList()

  trackEvent(eventName, { name: array.join(', ') })
  scrollToStart()
}

const filterList = () => {
  const list = document.querySelector('.data-filter-section .dashboards__list')
  if (!list) return

  const items = fullItemList

  const isItemMatched = item => {
    const {
      title,
      text,
      categories: itemCategories,
      sources: itemSources,
      destinations: itemDestinations
    } = item.parsedData

    const isSearchMatch = searchValue.length
      ? [title, text, ...itemSources, ...itemDestinations].some(it =>
          it.toLowerCase().trim().includes(searchValue)
        )
      : true

    const isCategoriesMatch =
      categoryValue === 'All' || categoryValue === 'All categories'
        ? true
        : itemCategories.includes(categoryValue)

    const isSourcesMatch = sourceValue.length
      ? sourceValue.some(item => itemSources.includes(item))
      : true

    const isDestinationsMatch = destinationValue.length
      ? destinationValue.some(item => itemDestinations.includes(item))
      : true

    return (
      isSearchMatch &&
      isCategoriesMatch &&
      isSourcesMatch &&
      isDestinationsMatch
    )
  }

  const getSourceCount = item => {
    return item.parsedData.sources.length
  }

  if (sourceValue.length > 0) {
    items.sort((a, b) => {
      const sourceCountA = getSourceCount(a)
      const sourceCountB = getSourceCount(b)

      if (sourceCountA === 1 && sourceCountB !== 1) {
        return -1
      } else if (sourceCountA !== 1 && sourceCountB === 1) {
        return 1
      } else {
        return 0
      }
    })
  } else {
    items.sort((a, b) => initialOrder.indexOf(a) - initialOrder.indexOf(b))
  }

  list.innerHTML = ''

  let showedMatchedItemsCount = 0
  let matchedItemsCount = 0

  items.forEach(item => {
    if (isItemMatched(item)) {
      if (showedMatchedItemsCount < visibleItemsCount) {
        item.classList.remove(ITEM_HIDDEN_CLASSNAME)
        showedMatchedItemsCount++
      } else {
        item.classList.add(ITEM_HIDDEN_CLASSNAME)
      }
      list.appendChild(item)
      matchedItemsCount++
    } else {
      item.classList.add(ITEM_HIDDEN_CLASSNAME)
    }
  })

  const isEmpty = items.every(item =>
    item.classList.contains(ITEM_HIDDEN_CLASSNAME)
  )

  list.classList.toggle('is-empty', isEmpty)

  const remainingItems = matchedItemsCount - visibleItemsCount

  document.querySelector(DASHBOARD_LOAD_MORE_SELECTOR).style.display =
    remainingItems > 0 ? 'inline-flex' : 'none'
}

const handleTabChange = function () {
  categoryValue = this.value
  visibleItemsCount = itemsPerPage
  updateURL()
  filterList()
  trackEvent('Marketing:Templates:Category selected', { name: categoryValue })
  scrollToStart()
}

const handleMobileTabChange = function () {
  categoryValue = this.value
  visibleItemsCount = itemsPerPage
  updateURL()
  filterList()
  trackEvent('Marketing:Templates:Category selected', { name: categoryValue })
  scrollToStart()
}

on('submit', '.dashboards__filter', e => {
  e.preventDefault()
})

on(
  'input',
  DASHBOARD_SEARCH_SELECTOR,
  debounce(e => {
    searchValue = e.target.value?.toLowerCase()?.trim() ?? ''
    filterList()
    trackEvent('Marketing:Templates:Search', { name: searchValue })
  })
)

on(
  'change',
  `[name="sources-checkboxes"], [name="categories-checkboxes"], [name="destinations-checkboxes"]`,
  handleCheckboxChange
)

on('change', TAB_SELECTOR, handleTabChange)

on('change', TAB_MOBILE_SELECTOR, handleMobileTabChange)

onDomReady(() => {
  const urlParams = new URLSearchParams(window.location.search)

  categoryValue = urlParams.get('category') || 'All'
  sourceValue = urlParams.getAll('source')
  destinationValue = urlParams.getAll('destination')
  searchValue = ''

  const categoryTabs = document.querySelectorAll(TAB_SELECTOR)
  categoryTabs.forEach(tab => {
    tab.checked = tab.value === categoryValue
  })

  const mobileTab = document.querySelector(TAB_MOBILE_SELECTOR)
  if (mobileTab) {
    mobileTab.value = categoryValue === 'All' ? 'All categories' : categoryValue
  }

  const categoryCheckboxes = document.querySelectorAll(
    `[name="categories-checkboxes"]`
  )

  const sourceCheckboxes = document.querySelectorAll(
    `[name="sources-checkboxes"]`
  )

  const destinationCheckboxes = document.querySelectorAll(
    `[name="destinations-checkboxes"]`
  )

  categoryCheckboxes.forEach(checkbox => {
    const checkboxValue = checkbox.value
    checkbox.checked = categoryValue.includes(checkboxValue)
  })

  sourceCheckboxes.forEach(checkbox => {
    const checkboxValue = checkbox.value
    checkbox.checked = sourceValue.includes(checkboxValue)
  })

  destinationCheckboxes.forEach(checkbox => {
    const checkboxValue = checkbox.value
    checkbox.checked = destinationValue.includes(checkboxValue)
  })

  fullItemList = Array.from(
    document.querySelectorAll('.data-filter-section .dashboard')
  ).map(item => {
    item.parsedData = {
      title: item.dataset.title,
      text: item.dataset.text,
      categories: JSON.parse(item.dataset.categories),
      sources: JSON.parse(item.dataset.sources),
      destinations: JSON.parse(item.dataset.destinations)
    }
    return item
  })

  initialOrder = fullItemList.slice()

  filterList()
})

on('click', DASHBOARD_LOAD_MORE_SELECTOR, () => {
  visibleItemsCount = fullItemList.length
  filterList()
})

on('click', DASHBOARD_MULTISELECT_CLEAR_SELECTOR, event => {
  const { collection } = event.target.dataset
  switch (collection) {
    case 'sources': {
      sourceValue = []
      break
    }
    case 'destinations': {
      destinationValue = []
      break
    }
  }
  updateURL()
  filterList()
})
