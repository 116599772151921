import { on } from 'delegated-events'
import { onDomReady } from 'utils/onDomReady'
import debounce from 'utils/debounce'
import { trackEvent } from 'api/index'
import updateControlsVisibility from 'utils/horizontalCarousel'

const SECTION_SELECTOR = '.data-filter-section__content'
const SEARCH_SELECTOR = '.search__input'
const TYPES_SELECTOR = '.base-checkbox__input'
const TAB_SELECTOR = '.data-tab'
const TAB_MOBILE_SELECTOR = '.data-filter-mobile'

const ITEM_HIDDEN_CLASSNAME = 'hidden'

const LOAD_MORE_SELECTOR = '.load-more-data'

const DEFAULT_TAB_VALUE = 'All'

const INTEGRATIONS_TYPE = 'integrations'
const METRIC_DIMENSION_TYPE = 'metric_dimension'

const eventType = {
  [INTEGRATIONS_TYPE]: 'Marketing:Source',
  [METRIC_DIMENSION_TYPE]: 'Marketing:MetricDimension'
}

const searchType = {
  [INTEGRATIONS_TYPE]: 'data source',
  [METRIC_DIMENSION_TYPE]: 'metrics and dimensions'
}

const itemsPerPage = () => {
  if (window.matchMedia('(max-width: 650px)').matches) {
    return 10
  }
  if (window.matchMedia('(max-width: 1022px)').matches) {
    return 20
  }
  return Infinity
}

const searchValues = {}
const tagValues = {}
const typeValues = {}

const visibleItemsCounts = {}
const showedMatchedItemsCounts = {}
const matchedItemsCounts = {}

function updateURL(filterType) {
  const urlParams = new URLSearchParams()

  if (tagValues[filterType]) {
    urlParams.set(`tag_${filterType}`, tagValues[filterType])
  }

  typeValues[filterType].forEach(type =>
    urlParams.append(`type_${filterType}`, type)
  )

  window.history.replaceState(null, '', `?${urlParams.toString()}`)
}

function filterList(filterType) {
  const section = document.querySelector(
    `.data-filter-section__content[data-filter-type='${filterType}'`
  )
  if (!section) return

  showedMatchedItemsCounts[filterType] = 0
  matchedItemsCounts[filterType] = 0

  const items = Array.from(section.querySelectorAll('li.data-item'))
  const popularFor = section.dataset.destination

  items.forEach(item => {
    const {
      title,
      tag: itemTag,
      type: itemType,
      popular: popular
    } = item.dataset

    const isSearchMatch = title
      .toLowerCase()
      .trim()
      .includes(searchValues[filterType])
    const itemTags = itemTag ? itemTag.split(',') : []

    const isCategoryMatch =
      !tagValues[filterType] ||
      tagValues[filterType] === 'All' ||
      itemTags.includes(tagValues[filterType])

    const isTypeMatch =
      typeValues[filterType].length === 0 ||
      typeValues[filterType].includes(itemType)
    const isPopular = popular && popular.includes(popularFor)
    const isTagPopular = tagValues[filterType] === 'Popular'
    const coreMatch = isSearchMatch && isTypeMatch
    const match = coreMatch && (isTagPopular ? isPopular : isCategoryMatch)

    if (match) {
      if (
        showedMatchedItemsCounts[filterType] < visibleItemsCounts[filterType]
      ) {
        item.classList.remove(ITEM_HIDDEN_CLASSNAME)
        showedMatchedItemsCounts[filterType]++
      } else {
        item.classList.add(ITEM_HIDDEN_CLASSNAME)
      }
      matchedItemsCounts[filterType]++
    } else {
      item.classList.add(ITEM_HIDDEN_CLASSNAME)
    }
  })

  const isEmpty = items.every(item =>
    item.classList.contains(ITEM_HIDDEN_CLASSNAME)
  )
  section.classList.toggle('is-empty', isEmpty)

  const remainingItems =
    matchedItemsCounts[filterType] - visibleItemsCounts[filterType]
  section.querySelector(LOAD_MORE_SELECTOR).style.display =
    remainingItems > 0 ? 'inline-flex' : 'none'

  const searchTextEl = section.querySelector('.data-empty__title')
  if (searchTextEl) {
    searchTextEl.textContent = searchValues[filterType]
      ? `Sorry, couldn’t find "${searchValues[filterType]}" on the ${searchType[filterType]} list`
      : 'Sorry, no results match your criteria'
  }

  section.dataset.filtered = Boolean(searchValues[filterType])

  if (filterType == METRIC_DIMENSION_TYPE) {
    const carouselElement = document.querySelector(
      '.metric-dimension.horizontal-carousel'
    )

    updateControlsVisibility(carouselElement)
  }
}

const scrollToStart = function () {
  const start = document.querySelector(
    '.data-filter-section--integrations .data-filter-section__box .anchor'
  )
  if (start) {
    start.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

function onTabChange(e, filterType) {
  tagValues[filterType] = e.target.value
  filterList(filterType)
  scrollToStart()
}

function getClosestFilterType(e) {
  return e.target.closest(SECTION_SELECTOR).dataset.filterType
}

on(
  'input',
  SEARCH_SELECTOR,
  debounce(e => {
    const filterType = getClosestFilterType(e)
    searchValues[filterType] = e.target.value?.toLowerCase()?.trim()
    filterList(filterType)
    trackEvent(`${eventType[filterType]}:Search`, {
      name: searchValues[filterType]
    })
  })
)

on('submit', '.data-filter-search', e => {
  e.preventDefault()
})

on('change', TAB_SELECTOR, e => {
  const filterType = getClosestFilterType(e)
  tagValues[filterType] = e.target.value
  onTabChange(e, filterType)
  updateURL(filterType)
  trackEvent(`${eventType[filterType]}:Category selected`, {
    name: tagValues[filterType]
  })
})

on('change', TAB_MOBILE_SELECTOR, e => {
  const filterType = getClosestFilterType(e)
  tagValues[filterType] = e.target.value
  onTabChange(e, filterType)
  updateURL(filterType)
  trackEvent(`${eventType[filterType]}:Category selected`, {
    name: tagValues[filterType]
  })
})

on('change', TYPES_SELECTOR, e => {
  const filterType = getClosestFilterType(e)
  typeValues[filterType] = Array.from(
    e.target.closest(SECTION_SELECTOR).querySelectorAll(TYPES_SELECTOR)
  )
    .filter(i => i.checked)
    .map(i => i.value)
  updateURL(filterType)
  filterList(filterType)
  scrollToStart()
  trackEvent(`${eventType[filterType]}:Type selected`, {
    name: typeValues[filterType].join(', ')
  })
})

on('click', LOAD_MORE_SELECTOR, e => {
  const filterType = getClosestFilterType(e)
  visibleItemsCounts[filterType] += itemsPerPage()
  filterList(filterType)
})

onDomReady(() => {
  const sections = document.querySelectorAll(SECTION_SELECTOR)
  if (!sections) return

  sections.forEach(section => {
    const filterType = section.dataset.filterType
    const urlParams = new URLSearchParams(window.location.search)

    searchValues[filterType] = ''
    if (filterType == INTEGRATIONS_TYPE) {
      tagValues[filterType] =
        urlParams.get(`tag_${filterType}`) || DEFAULT_TAB_VALUE
    }
    typeValues[filterType] = urlParams.getAll(`type_${filterType}`).length
      ? urlParams.getAll(`type_${filterType}`)
      : []

    const searchInputEl = section.querySelector(SEARCH_SELECTOR)
    if (searchInputEl) {
      searchInputEl.value = ''
    }

    const sourceTypeElms = Array.from(section.querySelectorAll(TYPES_SELECTOR))

    sourceTypeElms.forEach(el => {
      el.checked = typeValues[filterType].includes(el.value)
    })

    const sourceTabEl = section.querySelector(
      `${TAB_SELECTOR}[value="${tagValues[filterType]}"]`
    )
    if (sourceTabEl) {
      sourceTabEl.checked = true
    }

    const sourceTabMobileEl = section.querySelector(TAB_MOBILE_SELECTOR)
    if (sourceTabMobileEl) {
      sourceTabMobileEl.value = tagValues[filterType]
    }

    visibleItemsCounts[filterType] = itemsPerPage()

    filterList(filterType)
  })
})
