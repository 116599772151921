import { on } from 'delegated-events'

const REQUIRED_FIELDS = ['first_name', 'last_name', 'email', 'request_details']
const TYPE_CHECKBOX_FIELDS = [
  'data_visualization_type',
  'data_infrastructure_type',
  'business_analytics_type',
  'data_automation_type'
]
const INPUT_ERROR_CLASS = 'base-input--error'

const successIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <g clip-path="url(#a)">
      <path fill="#fff" fill-rule="evenodd" d="M16.173 6.403A7.145 7.145 0 0 0 2.859 9.769L2.855 10l.004.232a7.144 7.144 0 1 0 13.314-3.83ZM13.279 8.61a.75.75 0 0 0-1.06-1.06l-3.315 3.315L7.776 9.76a.75.75 0 1 0-1.05 1.072l1.658 1.624a.75.75 0 0 0 1.055-.006l3.84-3.84Z" clip-rule="evenodd"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z"/>
      </clipPath>
    </defs>
  </svg>
`
const alertIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2868 4.57534C17.5799 5.32197 18.6556 6.39338 19.4074 7.68351C20.1592 8.97363 20.561 10.4377 20.5731 11.9309C20.5851 13.424 20.207 14.8944 19.4762 16.1965C18.7453 17.4986 17.687 18.5873 16.4062 19.3547C15.1253 20.1221 13.6662 20.5417 12.1733 20.5719C10.6804 20.6021 9.2055 20.2419 7.8946 19.527C6.58371 18.812 5.48228 17.767 4.69934 16.4956C3.9164 15.2241 3.4791 13.7702 3.43074 12.2778L3.42645 12L3.43074 11.7222C3.47875 10.2416 3.90961 8.79863 4.68131 7.53406C5.45301 6.26949 6.53921 5.22646 7.83402 4.50665C9.12883 3.78684 10.5881 3.41482 12.0695 3.42686C13.5508 3.4389 15.0038 3.83458 16.2868 4.57534ZM10.0552 8.99451C9.76228 8.70162 9.2874 8.70162 8.99451 8.99451C8.70162 9.2874 8.70162 9.76228 8.99451 10.0552L10.9393 12L8.99451 13.9448C8.70162 14.2377 8.70162 14.7126 8.99451 15.0055C9.2874 15.2984 9.76228 15.2984 10.0552 15.0055L12 13.0607L13.9448 15.0055C14.2377 15.2984 14.7126 15.2984 15.0055 15.0055C15.2984 14.7126 15.2984 14.2377 15.0055 13.9448L13.0607 12L15.0055 10.0552C15.2984 9.76228 15.2984 9.2874 15.0055 8.99451C14.7126 8.70162 14.2377 8.70162 13.9448 8.99451L12 10.9393L10.0552 8.99451Z" fill="#fff"/>
  </svg>
`

on('submit', 'form#data-services-request-form', e => {
  e.preventDefault()
  const form = e.currentTarget
  const submitButton = document.getElementById('data-services-request-submit')
  submitButton.classList.remove('success', 'danger')
  submitButton.textContent = 'Submit'

  let hasErrors = false

  REQUIRED_FIELDS.forEach(field => {
    const input = document.getElementById(field)
    if (input) {
      input.classList.remove(INPUT_ERROR_CLASS)

      if (!input.value || !input.value.length) {
        hasErrors = true
        input.classList.add(INPUT_ERROR_CLASS)
      }
    }
  })

  if (hasErrors) {
    return
  }

  const inputValues = REQUIRED_FIELDS.reduce((agg, field) => {
    const input = document.getElementById(field)
    if (input) {
      return {
        ...agg,
        [field]: input.value
      }
    }

    return agg
  }, {})

  const values = TYPE_CHECKBOX_FIELDS.reduce((agg, field) => {
    const checkbox = document.getElementById(field)
    if (checkbox && checkbox.checked) {
      return {
        ...agg,
        [field]: checkbox.checked
      }
    }

    return agg
  }, inputValues)

  submitButton.setAttribute('disabled', 'disabled')

  fetch(form.action, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  })
    .then(() => {
      submitButton.classList.add('success')
      submitButton.innerHTML = `${successIcon} Success`
    })
    .catch(() => {
      submitButton.classList.add('danger')
      submitButton.innerHTML = `${alertIcon} Submitting went wrong`
    })
    .finally(() => {
      submitButton.removeAttribute('disabled')
      form.reset()
      setTimeout(() => {
        submitButton.removeAttribute('disabled')
        submitButton.classList.remove('success', 'danger')
        submitButton.textContent = 'Submit'
      }, 3000)
    })
})
