import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['slider', 'next', 'prev']

  connect() {
    const sliderElement = this.sliderTarget
    if (!sliderElement) return

    import('swiper').then(({ default: Swiper, Navigation }) => {
      Swiper.use(Navigation)

      new Swiper(sliderElement, {
        spaceBetween: 40,
        slidesPerView: 1,
        disabledClass: 'disable',
        navigation: {
          nextEl: this.nextTarget,
          prevEl: this.prevTarget
        },
        breakpoints: {
          769: {
            slidesPerView: 1.4
          }
        }
      })
    })
  }
}
