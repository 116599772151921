import Cookies from 'js-cookie'

import { onDomReady } from 'utils/onDomReady'

const REFERRAL_CODE_KEY = 'referral_code'
const REFERRAL_CODE_TTL = 30
const REFERRAL_CODE_MAX_LEN = 255
const IP_HOSTNAME_REGEX =
  /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/

onDomReady(() => {
  const queryString = window.location?.search
  if (!queryString) return

  const urlParams = new URLSearchParams(queryString)
  const referralCode = urlParams.get(REFERRAL_CODE_KEY)
  if (!referralCode) return
  if (referralCode.length > REFERRAL_CODE_MAX_LEN) return

  let domain = null
  // ignore ipv4 hostnames and 2 level domains
  const currentHostname = window.location?.hostname
  if (!IP_HOSTNAME_REGEX.test(currentHostname)) {
    const urlParts = currentHostname?.split('.') || []
    if (urlParts.length > 2) {
      domain = `.${urlParts.slice(1).join('.')}`
    }
  }

  const secure = window.location?.protocol === 'https:'
  Cookies.set(REFERRAL_CODE_KEY, referralCode, {
    expires: REFERRAL_CODE_TTL,
    path: '/',
    domain,
    secure
  })
})
