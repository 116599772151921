import { on } from 'delegated-events'

on('click', '.custom-integration-beacon', e => {
  e.preventDefault()

  if (!window.Beacon) return

  window.Beacon('open')
  window.Beacon('navigate', '/ask/message/')
  window.Beacon('prefill', {
    subject: 'Custom Integration Request',
    text: [
      `Hi, I would like to export data from [list the desired apps]. What would be the cost of such integration?`,
      '[Tip: Provide more details about your case so we can offer a solution that best fits your needs. If possible, explain what data you need to export, where to, whether you have specific requirements to the output format, etc.]'
    ].join('\n')
  })
})

on('click', '.premium-integration-beacon', e => {
  e.preventDefault()

  const sourceName = e.target.value ? e.target.value : '[list the desired apps]'

  if (!window.Beacon) return

  window.Beacon('open')
  window.Beacon('navigate', '/ask/message/')
  window.Beacon('prefill', {
    subject: 'Early access source setup request',
    text: [
      `Hi! I would like to request ${sourceName} setup. This is what I expect from the integration: ...`,
      '[Tip: Provide more details about your case so we can offer a solution that best fits your needs.]'
    ].join('\n')
  })
})

on('click', '.support-team-beacon', e => {
  e.preventDefault()

  if (!window.Beacon) return

  window.Beacon('open')
  window.Beacon('navigate', '/ask/message/')
  window.Beacon('prefill', {
    subject: 'Special terms for non-profit and educational organizations'
  })
})
