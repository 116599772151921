import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['slider', 'pagination', 'next', 'prev']

  connect() {
    const sliderElement = this.sliderTarget
    if (!sliderElement) return

    const hasNavigation = this.hasNextTarget && this.hasPrevTarget

    import('swiper').then(({ default: Swiper, Navigation, Pagination }) => {
      Swiper.use([Pagination, Navigation])

      new Swiper(sliderElement, {
        spaceBetween: 32,
        slidesPerView: 1,
        pagination: {
          bulletClass: 'swiper-pagination-bullet',
          el: this.paginationTarget,
          clickable: true
        },
        navigation: hasNavigation
          ? {
              nextEl: this.nextTarget,
              prevEl: this.prevTarget
            }
          : false,
        breakpoints: {
          1024: {
            slidesPerView: 3
          }
        }
      })
    })
  }
}
