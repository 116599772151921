// #goal show fullscreen navigation on mobile devices
// #dk both Safari and Google Chrome conditionally show url bar which breaks 100vh...
// https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
const onResize = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

window.addEventListener('resize', onResize)

onResize()
