import { on } from 'delegated-events'

const initIntervalToggle = container => {
  on('click', 'ul.interval-toggle-switch > li', event => {
    selectToggle({ container, tab: event.currentTarget })
  })
}

const selectToggle = ({ container, tab }) => {
  const tabs = container.querySelectorAll('ul.interval-toggle-switch > li')
  const tabContent = container.querySelectorAll('.interval-toggle-content')[0]
  tabs.forEach(tab => tab.classList.remove('active'))
  tab.classList.add('active')
  tabContent.setAttribute('data-display', tab.getAttribute('data-for'))
}

const getActiveToggle = container =>
  container.querySelector('ul.interval-toggle-switch > li.active')

export { initIntervalToggle, selectToggle, getActiveToggle }
