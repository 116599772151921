import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['expancedList', 'expanceButton', 'expanceColumn']

  toggleExpanceColumn(event) {
    const title = event.currentTarget
    const content = title.nextElementSibling
    const state = title.dataset.footerValue

    const isCollapsed = state === 'collapsed'
    const toggleTo = isCollapsed ? 'expanded' : 'collapsed'

    const contentHeight = content.scrollHeight
    const transitionSpeed = (contentHeight * 1.25).toFixed(2)

    title.classList.toggle('expanded')

    content.style.transition = `max-height ${transitionSpeed}ms ease-out, opacity ${transitionSpeed}ms ease-out`
    content.style.maxHeight = isCollapsed ? `${contentHeight}px` : null

    title.dataset.footerValue = toggleTo
  }

  toggleExpanceLinks() {
    this.expanceButtonTarget.classList.toggle('hidden')
    this.expancedListTarget.classList.toggle('hidden')

    const parent = this.expancedListTarget.parentElement
    const contentHeight = parent.scrollHeight
    this.expancedListTarget.parentElement.style.maxHeight = `${contentHeight}px`
  }
}
