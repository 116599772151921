import { memoize } from './memoize'

export const loadScript = memoize(
  url =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script')

      script.setAttribute('src', url)
      script.setAttribute('async', 'async')

      script.onload = resolve
      script.onerror = reject

      document.head.appendChild(script)
    })
)
