import { onDomReady } from 'utils/onDomReady'
import { on } from 'delegated-events'

onDomReady(() => {
  const sliderTracks = document.querySelectorAll('.infinite-slider')

  if (!sliderTracks) {
    return
  }

  sliderTracks.forEach(sliderTrack => {
    const isClientSlider = sliderTrack.closest('.clients__slider')

    if (isClientSlider && window.matchMedia('(min-width: 1025px)').matches) {
      return
    }

    const sliderItems = sliderTrack.querySelectorAll('.infinite-slider__item')
    const trackWidth = sliderTrack.offsetWidth
    const sliderItemsArray = Array.from(sliderItems)
    let totalWidth = sliderItemsArray.reduce(
      (agg, item) => agg + item.offsetWidth,
      0
    )
    let itemsWidth = totalWidth

    sliderTrack.style.setProperty('--totalWidth', `${totalWidth}px`)

    if (totalWidth < trackWidth) {
      while (totalWidth < trackWidth) {
        sliderItems.forEach(item => {
          const clonedItem = item.cloneNode(true)
          sliderTrack.appendChild(clonedItem)
          totalWidth += item.offsetWidth
        })
      }
    }

    let cloneOffset = 0

    while (cloneOffset < trackWidth) {
      sliderItems.forEach(item => {
        const clonedItem = item.cloneNode(true)
        sliderTrack.appendChild(clonedItem)
        cloneOffset += item.offsetWidth
      })
    }

    const direction = sliderTrack.dataset.direction || 'right'

    let animationDuration = itemsWidth / 20

    if (window.matchMedia('(max-width: 767px)').matches) {
      const speedMobile = sliderTrack.dataset.speedMobile || 1
      animationDuration = animationDuration * speedMobile
    } else {
      const speed = sliderTrack.dataset.speed || 1
      animationDuration = animationDuration * speed
    }

    if (direction === 'right') {
      sliderTrack.style.animation = `slide ${animationDuration}s linear infinite`
    } else if (direction === 'left') {
      sliderTrack.style.animation = `slide-left ${animationDuration}s linear infinite`
    }

    on('mouseover', '.infinite-slider--hover', function (event) {
      event.currentTarget.style.animationPlayState = 'paused'
    })

    on('mouseout', '.infinite-slider--hover', function (event) {
      event.currentTarget.style.animationPlayState = 'running'
    })
  })
})
