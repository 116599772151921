import { on } from 'delegated-events'
import { onDomReady } from 'utils/onDomReady'

const SHOW_ALL_TAG = 'All'
const HIDE_CLASSNAME = 'image-card--hidden'
const HIDE_EXCESS_DASHBOARD = 'image-card--excess'
const HIDE_BTN = 'image-cards-load-btn--hidden'

const visibleBlocks = () => {
  if (window.matchMedia('(max-width: 550px)').matches) {
    return 2
  } else if (window.matchMedia('(max-width: 850px)').matches) {
    return 3
  }
  return 8
}

const getMoreBtn = () => document.querySelector('.image-cards-load-btn')

const getDashboardItems = () =>
  Array.from(document.querySelectorAll('.image-cards__content .image-card'))

onDomReady(() => {
  if (getDashboardItems().length > visibleBlocks() + 1) {
    getMoreBtn()?.classList?.remove(HIDE_BTN)
  }

  getDashboardItems().forEach((item, i) => {
    if (i > visibleBlocks()) {
      item.classList.add(HIDE_EXCESS_DASHBOARD)
    }
  })
}, false)

on('change', 'input[name="dashboards-tab"]', e => {
  const input = e.target
  const { tag } = input.dataset
  if (!tag) {
    return
  }

  const currentTags = []

  getDashboardItems().forEach((item, i) => {
    if (SHOW_ALL_TAG === tag) {
      item.classList.remove(HIDE_EXCESS_DASHBOARD, HIDE_CLASSNAME)

      if (i > visibleBlocks()) {
        item.classList.add(HIDE_EXCESS_DASHBOARD)
        getMoreBtn()?.classList?.remove(HIDE_BTN)
      }
      return
    }

    const { tags } = item.dataset
    const normalizedTags = tags.split(',')

    if (normalizedTags.includes(tag)) {
      item.classList.remove(HIDE_CLASSNAME, HIDE_EXCESS_DASHBOARD)

      currentTags.push(item)

      if (currentTags.length > visibleBlocks() + 1) {
        item.classList.add(HIDE_EXCESS_DASHBOARD)
        getMoreBtn()?.classList?.remove(HIDE_BTN)
      } else {
        getMoreBtn()?.classList?.add(HIDE_BTN)
      }
    } else {
      item.classList.add(HIDE_CLASSNAME)
      item.classList.remove(HIDE_EXCESS_DASHBOARD)
    }
  })
})

on('click', '.image-cards-load-btn', function () {
  getDashboardItems().forEach(item => {
    item.classList.remove(HIDE_EXCESS_DASHBOARD)
  })

  this.classList.add(HIDE_BTN)
})
