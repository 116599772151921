import { onDomReady } from 'utils/onDomReady'

onDomReady(() => {
  const screenWidthCondition = window.matchMedia('(max-width: 1023px)')

  if (screenWidthCondition.matches) {
    const carouselElement = document.querySelector('.grid-component__slider')
    if (!carouselElement) return

    import('swiper').then(({ default: Swiper, Navigation, Pagination }) => {
      Swiper.use([Navigation, Pagination])

      new Swiper(carouselElement, {
        spaceBetween: 32,
        slidesPerView: 1,
        disabledClass: 'disable',
        pagination: {
          bulletClass: 'swiper-pagination-bullet',
          el: '.grid-component__pagination',
          clickable: true
        }
      })
    })
  }
})
