import { onDomReady } from 'utils/onDomReady'

onDomReady(() => {
  const carouselElement = document.querySelector('.compare-price__slider')
  if (!carouselElement) return

  import('swiper').then(({ default: Swiper, Navigation }) => {
    Swiper.use([Navigation])

    new Swiper(carouselElement, {
      spaceBetween: 32,
      slidesPerView: 1,
      disabledClass: 'disable',
      navigation: {
        nextEl: '.compare-price__control_next',
        prevEl: '.compare-price__control_prev'
      },
      breakpoints: {
        551: {
          slidesPerView: 'auto'
        },
        1024: {
          slidesPerView: 3
        }
      }
    })
  })
})
