import { Controller } from '@hotwired/stimulus'
import * as Sentry from '@sentry/browser'
import SlimSelect from 'slim-select'

const EXTRACT_JSON_PATTERN = '_ejp'

export default class extends Controller {
  static targets = [
    'multiselect',
    'link',
    'radioButton',
    'source',
    'destination',
    'url'
  ]

  static values = {
    apiEndpoint: String,
    appHost: String
  }

  connect() {
    this.handleChange = this.handleChange.bind(this) // fix this for listener

    this.selectedSource = null

    fetch(`//${this.appHostValue}${this.apiEndpointValue}`)
      .then(response => response.json())
      .then(data => {
        this.selectedSource = this.getSelectedSource(data)

        if (!this.selectedSource) {
          this.urlTarget.style.display = 'none'
          return
        }

        const slimSelectData = this.generateSlimSelectData()
        const transformedOptions = this.transformDataToOptions(slimSelectData)

        this.initializeSlimSelect(transformedOptions)
        this.setSelectDefaultData()
        this.updateLinkState()
      })
      .catch(error => {
        this.urlTarget.style.display = 'none'
        Sentry.captureException(error)
      })
  }

  disconnect() {
    this.multiselectTarget?.removeEventListener('change', this.handleChange)
    this.select?.destroy()
  }

  getSelectedSource(data) {
    return data.sources.find(
      source => source.key === this.sourceTarget.dataset.sourceKey
    )
  }

  generateSlimSelectData() {
    return this.selectedSource.params.main.values.reduce((acc, value) => {
      const groupIndex = acc.findIndex(group => group.text === value.group)
      if (groupIndex !== -1) {
        acc[groupIndex].options.push({ text: value.label, value: value.value })
      } else {
        acc.push({
          text: value.group,
          options: [{ text: value.label, value: value.value }]
        })
      }
      return acc
    }, [])
  }

  transformDataToOptions(slimSelectData) {
    if (slimSelectData.length === 1) {
      return slimSelectData[0].options.map(option => ({
        text: option.text,
        value: option.value
      }))
    } else {
      return slimSelectData.map(group => ({
        label: group.text,
        options: group.options.map(option => ({
          text: option.text,
          value: option.value
        }))
      }))
    }
  }

  initializeSlimSelect(transformedOptions) {
    const {
      params: {
        main: { field }
      }
    } = this.selectedSource

    if (field === 'select') {
      this.urlTarget.querySelector('.base-input-label').textContent =
        'Report type'
      this.multiselectTarget.removeAttribute('multiple')
      this.multiselectTarget.classList.add('ss-main--single')
    }

    const settings = {
      closeOnSelect: field === 'select',
      allowDeselect: true,
      maxSelected: 20,
      keepOrder: true
    }

    this.select = new SlimSelect({
      select: this.multiselectTarget,
      data: transformedOptions,
      settings: settings
    })
    this.multiselectTarget.addEventListener('change', this.handleChange)
  }

  updateLinkState() {
    const {
      params: {
        main: { field, name, values },
        from,
        to
      }
    } = this.selectedSource

    const link = this.linkTarget
    const selectedOption = this.select.getSelected()
    const selectedJSONOptions = selectedOption.map(
      it => values.find(v => v.value === it) || it
    )

    const radioButtons = this.radioButtonTargets.filter(
      radioButton => radioButton.checked
    )
    const selectedPeriod = radioButtons.length > 0 ? radioButtons[0].value : ''

    const sourceKey = this.sourceTarget.dataset.sourceKey
    const destinationKey = this.destinationTarget.dataset.destinationKey

    const queryParams = new URLSearchParams({
      source: sourceKey,
      destination: destinationKey,
      [`source_params[${from.name}]`]: selectedPeriod,
      [`source_params[${to.name}]`]: '{{today}}'
    })

    if (field === 'select') {
      queryParams.set(
        `source_params[${name}${EXTRACT_JSON_PATTERN}]`,
        JSON.stringify(selectedJSONOptions[0])
      )
    } else {
      selectedJSONOptions.forEach(option => {
        queryParams.append(
          `source_params[${name}${EXTRACT_JSON_PATTERN}][]`,
          JSON.stringify(option)
        )
      })
    }

    const url = `//${this.appHostValue}/app/importers/build?${queryParams}`

    link.setAttribute('href', url)

    if (selectedOption.length) {
      link.removeAttribute('disabled')
    } else {
      link.setAttribute('disabled', 'disabled')
    }
  }

  setSelectDefaultData() {
    const selectedOptions =
      this.multiselectTarget.dataset?.selectedOptions?.split(',')

    this.select.setSelected(selectedOptions)
  }

  handleChange() {
    this.updateLinkState()
  }
}
