import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['slider', 'pagination']

  connect() {
    const sliderElement = this.sliderTarget
    if (!sliderElement) return

    if (window.innerWidth < 1024) {
      import('swiper').then(({ default: Swiper, Pagination, Grid }) => {
        Swiper.use([Pagination, Grid])

        new Swiper(sliderElement, {
          slidesPerView: 1,
          grid: {
            rows: 2
          },
          pagination: {
            bulletClass: 'swiper-pagination-bullet',
            el: this.paginationTarget,
            clickable: true
          }
        })
      })
    }
  }
}
