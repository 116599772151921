import Cookies from 'js-cookie'
import { on } from 'delegated-events'

import { onDomReady } from 'utils/onDomReady'

onDomReady(() => {
  setTimeout(() => {
    if (window.Cookiebot) return

    const consent = document.querySelector('.cookie-consent')
    if (!consent) return

    if (!Cookies.get('hide_cookie_consent')) {
      consent.classList.add('visible')
    }

    on('click', '#cookieConsentButton', () => {
      Cookies.set('hide_cookie_consent', 'yes', {
        expires: 365,
        path: '/'
      })
      consent.classList.remove('visible')
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'cookie_consent_update' })
      }
    })
  }, 5000)
})
