import { onDomReady } from 'utils/onDomReady'

onDomReady(() => {
  const carouselElement = document.querySelector('.testimonials-carousel')
  if (!carouselElement) return

  import('swiper').then(({ default: Swiper, Navigation }) => {
    Swiper.use([Navigation])

    new Swiper(carouselElement, {
      spaceBetween: 20,
      slidesPerView: 1.1,
      disabledClass: 'disable',
      navigation: {
        nextEl: '.testimonials-carousel__control_next',
        prevEl: '.testimonials-carousel__control_prev'
      },
      breakpoints: {
        391: {
          slidesPerView: 1
        },
        769: {
          slidesPerView: 2.3
        },
        1441: {
          slidesPerView: 3.3
        }
      }
    })
  })
})
