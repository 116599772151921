import 'marketing.sass'
import Rails from '@rails/ujs'
import * as Sentry from '@sentry/browser'
import { on } from 'delegated-events'

import 'utils/drawer'
import 'utils/simpleBar'
import 'utils/accordion'
import 'utils/filterData'
import 'utils/headerNav'
import 'utils/dashboardsFilter'
import 'utils/multiselect'
import 'utils/rolesTabs'
import 'utils/integrationsPopupPrevent'
import 'utils/beacons'
import 'utils/cookieConsent'
import 'utils/referralCodeTracking'
import 'utils/appHeight'
import 'utils/tooltip'
import 'utils/wistiaLazyLoading'
import 'utils/imgHighlights'
import 'utils/infiniteSlider'

import 'marketing/data-services-request'
import 'marketing/websummit-form'
import 'marketing/plans'
import 'marketing/image-cards'

import 'utils/testimonialsCarousel'
import 'utils/destinationsCarousel'
import 'utils/similarSourcesCarousel'
import 'utils/compareListCarousel'
import 'utils/gridComponentCarousel'
import 'utils/horizontalCarousel'

import 'stimulus'

import { onDomReady } from 'utils/onDomReady'
import { initIntervalToggle } from 'utils/intervalToggle'
import { getUserInfo } from 'api'

Rails.start()

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.RAILS_ENV,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.05,
    maxBreadcrumbs: 50,
    normalizeDepth: 3
  })
}

on('click', '.collapsable > h5', ({ currentTarget }) => {
  currentTarget.parentNode?.classList?.toggle('collapsed')
})

onDomReady(() => {
  Array.from(document.querySelectorAll('.interval-toggle-container')).forEach(
    container => initIntervalToggle(container)
  )

  getUserInfo().then(response => {
    if (!response) {
      return
    }

    const { data } = response
    if (!data) {
      return
    }

    // gtm
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ userId: data.id })
    // hj
    if (window.hj) {
      window.hj('identify', null, {
        user_id: data.id,
        account_id: data.selected_account_id
      })
    }
    // helpscout
    if (window.Beacon) {
      window.Beacon('identify', {
        email: data.email
      })

      window.Beacon('prefill', {
        email: data.email
      })
    }
    // clarity
    if (window.clarity) {
      window.clarity('set', 'user_id', data.id)
      window.clarity('set', 'account_id', data.selected_account_id)
    }

    if (data.email) {
      const typeformLink = document.querySelector('.dashboards-typeform-link')
      if (typeformLink) {
        typeformLink.href = `https://railsware2.typeform.com/to/ZqwOYff6#email=${encodeURIComponent(
          data.email
        )}`
      }
    }
  })
})
