import { on } from 'delegated-events'
import ProgressBar from 'progressbar.js'

let defaultIndexValues = {}
let cuttentActiveItem = {}
let isIntervalActive = {}
let intervalIds = {}
let progressBarLine = {}
let pausedProgressBars = {}

const timerDuration = 15000

const updateProgressBar = (progressBarElement, progressBarKey) => {
  if (progressBarLine[progressBarKey]) {
    progressBarLine[progressBarKey].set(0)
  } else {
    progressBarLine[progressBarKey] = new ProgressBar.Line(progressBarElement, {
      strokeWidth: 5,
      easing: 'linear',
      duration: timerDuration,
      trailWidth: 5,
      svgStyle: { width: '100%', height: '100%' }
    })
  }

  progressBarLine[progressBarKey].animate(1.0)
}

const updateAccordionState = (containerId, index) => {
  const container = document.querySelector(
    `.img-highlights[data-container-id="${containerId}"]`
  )
  if (!container) return

  const items = container.querySelectorAll(
    '.img-highlights-accordion__item > button'
  )
  const images = container.querySelectorAll('.img-highlights-img__wrap')

  items.forEach((item, itemIndex) => {
    item.setAttribute('aria-expanded', itemIndex == index ? 'true' : 'false')
  })

  images.forEach((image, imageIndex) => {
    image.classList.toggle('active', imageIndex == index)
  })

  const activeItem = items[index]
  const content = activeItem.nextElementSibling
  const progressBarElement = content.querySelector(
    '.img-highlights-accordion__progress-bar'
  )
  const progressBarKey = `${containerId}-${index}`
  const contentHeight = content.scrollHeight
  const transitionSpeed = (contentHeight * 1.25).toFixed(2)

  content.style.transition = `max-height ${transitionSpeed}ms ease-out, opacity ${transitionSpeed}ms ease-out`
  content.style.maxHeight = `${contentHeight}px`

  updateProgressBar(progressBarElement, progressBarKey)
  startAutoSwitching(containerId, items.length)
}

const setDefaultIndex = (containerId, defaultIndexValue) => {
  defaultIndexValues[containerId] = defaultIndexValue
  cuttentActiveItem[containerId] = defaultIndexValue

  updateAccordionState(containerId, defaultIndexValue)
}

const updateAccordionFromLink = () => {
  const pageHash = window.location.hash.substring(1)
  const splitHash = pageHash.split('?')

  const blockId = splitHash[0]
  const blockIndex = parseInt(splitHash[1], 10)

  const element = document.getElementById(blockId)
  element?.scrollIntoView({
    behavior: 'smooth'
  })

  if (blockId && blockIndex) {
    cuttentActiveItem[blockId] = blockIndex
    resetAutoSwitching(blockId)
    updateAccordionState(blockId, blockIndex)
  }
}

function autoSwitchAccordion(containerId, numItems) {
  const container = document.querySelector(
    `.img-highlights[data-container-id="${containerId}"]`
  )
  if (!container) return

  const currentIndex = cuttentActiveItem[containerId] || 0
  const nextIndex = (currentIndex + 1) % numItems

  cuttentActiveItem[containerId] = nextIndex
  updateAccordionState(containerId, nextIndex)
}

function startAutoSwitching(containerId, numItems) {
  if (!isIntervalActive[containerId]) {
    intervalIds[containerId] = setInterval(() => {
      autoSwitchAccordion(containerId, numItems)
    }, timerDuration)
    isIntervalActive[containerId] = true
  }
}

function resetAutoSwitching(containerId) {
  if (isIntervalActive[containerId]) {
    clearInterval(intervalIds[containerId])
    isIntervalActive[containerId] = false
  }
}

function pauseAutoSwitching(containerId, progressBarKey) {
  if (isIntervalActive[containerId]) {
    clearInterval(intervalIds[containerId])
    progressBarLine[progressBarKey].pause()
    pausedProgressBars[progressBarKey] = progressBarLine[progressBarKey].value()
    isIntervalActive[containerId] = false
  }
}

function resumeAutoSwitching(containerId, progressBarKey, numItems) {
  if (!isIntervalActive[containerId]) {
    const pausedProgress = pausedProgressBars[progressBarKey]
    const remainingTime = (1.0 - pausedProgress) * timerDuration

    progressBarLine[progressBarKey].resume()

    const timerId = setTimeout(() => {
      autoSwitchAccordion(containerId, numItems)

      intervalIds[containerId] = setInterval(() => {
        autoSwitchAccordion(containerId, numItems)
      }, timerDuration)
    }, remainingTime)

    intervalIds[containerId] = timerId
    isIntervalActive[containerId] = true
  }
}

function updatePauseResumeAutoSwitching(containerId, itemsLength) {
  const container = document.querySelector(
    `.img-highlights[data-container-id="${containerId}"]`
  )
  if (!container) return

  const isContainerVisible = isElementInViewport(container)
  const currentIndex = cuttentActiveItem[containerId] || 0
  const progressBarKey = `${containerId}-${currentIndex}`

  if (isContainerVisible) {
    if (!isIntervalActive[containerId]) {
      resumeAutoSwitching(containerId, progressBarKey, itemsLength)
    }
  } else {
    if (isIntervalActive[containerId]) {
      pauseAutoSwitching(containerId, progressBarKey)
    }
  }
}

function isElementInViewport(el, visibleThreshold = 0.6) {
  const rect = el.getBoundingClientRect()
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight

  return (
    rect.top + (1 - visibleThreshold) * rect.height >= 0 &&
    rect.top + visibleThreshold * rect.height <= windowHeight
  )
}

window.addEventListener('load', function () {
  const containers = document.querySelectorAll('.img-highlights')

  containers.forEach(container => {
    const { containerId } = container.dataset
    const items = container.querySelectorAll(
      '.img-highlights-accordion__item > button'
    )
    const itemsContent = container.querySelectorAll(
      '.img-highlights-accordion__item .img-highlights-accordion__box'
    )
    const itemsLength = itemsContent.length

    function toggleAccordion({ currentTarget: button }) {
      const isExpanded = button.getAttribute('aria-expanded') === 'true'

      if (isExpanded) {
        return
      }

      const buttonIndex = Array.from(items).indexOf(button)

      cuttentActiveItem[containerId] = buttonIndex

      resetAutoSwitching(containerId)
      updateAccordionState(containerId, buttonIndex)
    }

    function pauseAccordion() {
      const itemIndex = Array.from(itemsContent).indexOf(this)
      const progressBarKey = `${containerId}-${itemIndex}`
      pauseAutoSwitching(containerId, progressBarKey)
    }

    function resumeAccordion() {
      const itemIndex = Array.from(itemsContent).indexOf(this)
      const progressBarKey = `${containerId}-${itemIndex}`
      resumeAutoSwitching(containerId, progressBarKey, itemsLength)
    }

    items.forEach(item => item.addEventListener('click', toggleAccordion))
    itemsContent.forEach(itemBlock => {
      itemBlock.addEventListener('mouseenter', pauseAccordion)
      itemBlock.addEventListener('mouseleave', resumeAccordion)
    })

    if (items.length > 0) {
      const defaultIndexValue = defaultIndexValues[containerId] || 0
      setDefaultIndex(containerId, defaultIndexValue)
      pauseAutoSwitching(containerId, `${containerId}-${defaultIndexValue}`)
    }

    window.addEventListener('scroll', () =>
      updatePauseResumeAutoSwitching(containerId, itemsLength)
    )
  })

  if (containers.length > 0) {
    setTimeout(updateAccordionFromLink, 500)
    on('click', '.navigation-submenu__link', () => {
      setTimeout(updateAccordionFromLink, 100)
    })
  }
})
