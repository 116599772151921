import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['video', 'pauseButton']

  connect() {
    this.playCount = 0
    this.maxPlays = 2.8
    this.fractionalPartPlayed = false
    this.manualPause = false
    this.videoTarget.loop = false
    this.videoTarget.addEventListener('ended', this.handleVideoEnded.bind(this))
    this.videoTarget.addEventListener('play', this.handlePlay.bind(this))
    this.videoTarget.addEventListener('pause', this.handlePause.bind(this))
    this.videoTarget.addEventListener(
      'timeupdate',
      this.checkPartialPlay.bind(this)
    )
    this.updatePauseButtonVisibility()
  }

  handleVideoEnded() {
    this.playCount++
    if (this.playCount < Math.floor(this.maxPlays)) {
      this.videoTarget.play()
    } else if (!this.fractionalPartPlayed) {
      this.videoTarget.currentTime = 0
      this.videoTarget.play()
    }
  }

  checkPartialPlay() {
    const fractionalPart = this.maxPlays - Math.floor(this.maxPlays)
    const fractionalPlayTime = this.videoTarget.duration * fractionalPart
    if (
      this.playCount === Math.floor(this.maxPlays) &&
      this.videoTarget.currentTime >= fractionalPlayTime
    ) {
      this.videoTarget.pause()
      this.fractionalPartPlayed = true
    }
  }

  pauseVideo() {
    this.manualPause = true
    if (!this.videoTarget.paused) {
      this.videoTarget.pause()
    } else {
      this.videoTarget.play()
    }
  }

  handlePlay() {
    if (this.fractionalPartPlayed && this.manualPause) {
      this.fractionalPartPlayed = false
      this.playCount = 0
    }
    this.manualPause = false
    this.updatePauseButtonVisibility()
  }

  handlePause() {
    this.updatePauseButtonVisibility()
  }

  updatePauseButtonVisibility() {
    if (this.videoTarget.paused) {
      this.pauseButtonTarget.style.display = 'flex'
    } else {
      this.pauseButtonTarget.style.display = 'none'
    }
  }
}
