import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['operand', 'result']
  static values = { formula: String }

  connect() {
    this.element.addEventListener('submit', event => {
      event.preventDefault()

      this.calculate()
    })
  }

  calculate() {
    const allOperandsFilled = this.operandTargets.every(
      operand => operand.value.trim() !== ''
    )

    if (!allOperandsFilled) {
      return
    }

    let formula = this.formulaValue
    this.operandTargets.forEach(operand => {
      const value = parseFloat(operand.value) || 0
      formula = formula.replace(new RegExp(operand.dataset.name, 'g'), value)
    })

    try {
      const result = eval(formula)
      this.resultTarget.innerText = result.toFixed(2)
    } catch (e) {
      this.resultTarget.innerText = 'Error'
    }
  }
}
